import React, { useState } from 'react';
import PaypalSubs from "./PaypalSubs";
import PaypalTime from "./PaypalTime";
import Regions from "./Regions";
import Systems from "./Systems";
import StaticIP from "./StaticIP";
import appConfig from './AppConfig';
import {
  Flex,
//  Heading,
//  Image,
//  View,
} from "@aws-amplify/ui-react";

//v1.7

function Body(props) { 


  const [state, setState] = useState("--");
  const [state2, setState2] = useState("--");
  const [state3, setState3] = useState("--");
  const [state4, setState4] = useState("--");
  const [state4a, setState4a] = useState("--");
  const [state5, setState5] = useState("--");
  const [uopt, setUopt] = useState("0");
  const [uopt2, setUopt2] = useState("0");
  const [uopt3, setUopt3] = useState("0");

 
  const [ureg, setUreg] = useState("--");
  const [uclass, setUclass] = useState("--");
  const [utype, setUtype] = useState("--");
  const [uip, setUip] = useState("--");


 function stateChange(f) {
        setState(f);
        props.funct(f);
        props.key = "";
  };

  function stateChange2(f) {
    setState2(f);
    props.funct2(f);
    props.key2 = "";
};

function stateChange3(f) {
  setState3(f);
  props.funct3(f);
  props.key2 = "";
};

function stateChange4(f) {
  setState4(f);
  props.funct4(f);
  props.key2 = "";
};

function stateChange4a(f) {
  setState4a(f);
  props.funct4a(f);
  props.key2 = "";
};

function stateChange5(f) {
  setState5(f);
  props.funct5(f);
  props.key2 = "";
};


function dispTime(a){
  var h = Math.trunc(a/60);
  var m = a % 60;
  if (h === 0) {
    var hours = '';
  } else if (h === 1) {
     hours = '1hr ';
  } else {
     hours = h + 'hrs ';
  };
  var minutes = m + "min";
  return hours + minutes ;
}



  function goTOS() {
    //Open help/support page in new window
     window.open("https://" + appConfig.websitename + "/tos");
  }
  
  
  function goAcceptTOS() {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({"uname": props.uname,"act": "tos_"+ appConfig.apiname});
  
    var requestOptions = {
     method: 'POST',
     headers: {
      'Content-Type': 'application/json',
      'X-Api-Key': 'tR2J3lBfVD2vYc9TdvtRW1mhT5yn0rpP7fmjN1JV'
    },
     body: raw,
     redirect: 'follow'
   };
     // make API call with parameters and use promises to get response
    fetch("https://pmcbp4q5te.execute-api.us-east-2.amazonaws.com/dev/", requestOptions)
    .catch(err => alert(err));
     //alert("TOS Accepted");
  
  
  
   alert("TOS Accepted");
   stateChange2("1")
  
  
  };




  function goOpt3() {

    var raw = JSON.stringify({"uname": props.uname,"act": "status_"+ appConfig.apiname});
  var requestOptions = {
      method: 'POST',
      headers: {
              'Content-Type': 'application/json',
            'X-Api-Key': 'tR2J3lBfVD2vYc9TdvtRW1mhT5yn0rpP7fmjN1JV'
              },
      body: raw,
      redirect: 'follow',
   };
 

   
     fetch("https://pmcbp4q5te.execute-api.us-east-2.amazonaws.com/dev/", requestOptions)
   .then((response) => response.text())
   .then((responce) => GetData2(responce))

  }


  function GetData2(responce) {

   var tmpstr = responce.substring( responce.indexOf("|") + 1,responce.lastIndexOf("~") );
  //var tmpstat = tmpstr.split("!")[0];
  var tmpdur = tmpstr.split("!")[2];
  tmpdur = tmpdur.split(" ")[0];
  var tmpbank = tmpstr.split("!")[1];
//alert(tmpbank)
  //alert(tmpdur)
  var tmpnum = (Number(tmpbank) - Number(tmpdur));

    if (tmpnum < appConfig.maxcred) { 
      setUopt2("1");

    } else { 

        alert("Current Credits at " + String(tmpnum) +  ".  \nMust be below the limit of " + dispTime(appConfig.maxcred) + " before purchasing more credits");

     };

  }


  function goOpt1() {

    var raw = JSON.stringify({"uname": props.uname,"act": "status_"+ appConfig.apiname});
  var requestOptions = {
      method: 'POST',
      headers: {
              'Content-Type': 'application/json',
            'X-Api-Key': 'tR2J3lBfVD2vYc9TdvtRW1mhT5yn0rpP7fmjN1JV'
              },
      body: raw,
      redirect: 'follow',
   };
   
     fetch("https://pmcbp4q5te.execute-api.us-east-2.amazonaws.com/dev/", requestOptions)
   .then((response) => response.text())
   .then((responce) => GetData(responce));
 
  }

  function GetData(responce) {

    var tmpstr = responce.substring( responce.indexOf("|") + 1,responce.lastIndexOf("~") );
    var tmpstat = tmpstr.split("!")[0];
    if (tmpstat === "Stopped") { 
      setUopt("1");
      setUopt3("0");
     } else { 
     // alert("Options are disabled while Cloud PC is Running");
     setUopt("1");
      setUopt3("1");
     };
  }

  
  function goOpt2() {
    //Open help/support page in new window 
    // window.open("https://gsalpha.com/help");
    setUopt("0");
    //statusButton()
    // alert("disabled");
  }

  function goOpt4() {
    //Open help/support page in new window 
    // window.open("https://gsalpha.com/help");
    setUopt2("0");
    //statusButton()
    // alert("disabled");
  }

  function goOpt6() {
    //Open help/support page in new window 
    // window.open("https://gsalpha.com/help");
    setUopt3("0");
    setUopt("0");
    //statusButton()
    // alert("disabled");
  }


  function goHelp() {
    //Open help/support page in new window 
     window.open("https://" + appConfig.websitename +"/help");
  }
  

  function phoneButton(phone) {
    var raw = JSON.stringify({"uname": props.uname,"act": "phone_"+ appConfig.apiname,"dat": phone});
    var requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-Api-Key': 'tR2J3lBfVD2vYc9TdvtRW1mhT5yn0rpP7fmjN1JV'
      },
      body: raw,
      redirect: 'follow'
    };
    fetch("https://pmcbp4q5te.execute-api.us-east-2.amazonaws.com/dev/", requestOptions);
    if (phone === "DISABLE") {
      alert("Phone Number Disabled")
    } else {
      alert("Phone Number Updated")
    }
  }


  
function goReset() {
  //Open help/support page in new window 
  // window.open("https://gsalpha.com/help");

  const input = prompt("Enter 'yes' below to confirm full data erase / cloud pc reset");
  if (input === null) { 
    alert("Reset Cancelled")
  } else if( input === 'yes' ) {
    resetButton();
    alert("Data will be reset at next Start Up");
  } else {
    alert("Reset Cancelled") 
  }


}


function resetButton() {
  var raw = JSON.stringify({"uname": props.uname,"act": "reset_"+ appConfig.apiname});
  var requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Api-Key': 'tR2J3lBfVD2vYc9TdvtRW1mhT5yn0rpP7fmjN1JV'
    },
    body: raw,
    redirect: 'follow'
  };
  fetch("https://pmcbp4q5te.execute-api.us-east-2.amazonaws.com/dev/", requestOptions);
}

  function goAlert() {


    var raw = JSON.stringify({"uname": props.uname,"act": "status_"+ appConfig.apiname});
    var requestOptions = {
        method: 'POST',
        headers: {
                'Content-Type': 'application/json',
              'X-Api-Key': 'tR2J3lBfVD2vYc9TdvtRW1mhT5yn0rpP7fmjN1JV'
                },
        body: raw,
        redirect: 'follow',
     };
       fetch("https://pmcbp4q5te.execute-api.us-east-2.amazonaws.com/dev/", requestOptions)
     .then((response) => response.text())
     .then((responce) => GetData3(responce))

    }

     function GetData3(responce) {

      var tmpstr = responce.substring( responce.indexOf("|") + 1,responce.lastIndexOf("~") );
      //var tmpstat = tmpstr.split("!")[0];
      var tmpuphone = tmpstr.split("!")[11];

    const input = prompt("Current Number: " + tmpuphone + " \n\nPlease enter the desired SMS Alert Phone# (use the format +1XXX5555555) or enter DISABLE to turn off\n");
    if (input === null) { 
      alert("Cancelled")
    } else {
      var str = input.toUpperCase();
      if ((str) === "DISABLE") {
        phoneButton(str)  
      } else {
        if ( input.includes("+1")) {
          if (input.length === 12 ) {
            //allow number change     
            phoneButton(input)   
            } else {
              alert("Cancelled - Incorrect Length")
            }
         } else {
            alert("Cancelled - Missing Country Code")
         }
      }
    }


  }






  
function goRollback() {
  const input = prompt("Enter 'yes' below to confirm cloud pc rollback to last backup");
  if (input === null) { 
    alert("Rollback Cancelled")
  } else if( input === 'yes' ) {
    rollbackButton();
    alert("STOP the cloud PC to complete the Rollback");
  } else {
    alert("Rollback Cancelled") 
  }
}

function rollbackButton() {
  var raw = JSON.stringify({"uname": props.uname,"act": "rollback_"+ appConfig.apiname});
  var requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Api-Key': 'tR2J3lBfVD2vYc9TdvtRW1mhT5yn0rpP7fmjN1JV'
    },
    body: raw,
    redirect: 'follow'
  };
  fetch("https://pmcbp4q5te.execute-api.us-east-2.amazonaws.com/dev/", requestOptions);
}


function goSDT() {


  
  const input = prompt("Please enter the desired shut down time in minuites.", "1440" );
  if (input === null) { 
    alert("Cancelled")
  } else {
    
    if (isNaN(input))
    {
    alert("Invalid number");
    } else { 
      sdtButton(input) 
    }

  }
}

function sdtButton(sdt) {

  var raw = JSON.stringify({"uname": props.uname,"act": "sdt_"+ appConfig.apiname,"dat": sdt});
    var requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Api-Key': 'tR2J3lBfVD2vYc9TdvtRW1mhT5yn0rpP7fmjN1JV'
    },
    body: raw,
    redirect: 'follow'
  };
  fetch("https://pmcbp4q5te.execute-api.us-east-2.amazonaws.com/dev/", requestOptions);
}



  function goBlank() {};


if (state === "--" ) {
  var tmpustanding = props.ustanding;
} else if (state === "enabled") {
  tmpustanding = "enabled"
};

if (state2 === "--") {
  var tmputos = props.utos
} else if (state2 === "1") {
  tmputos = "1"
};

if (state3 === "--") {
  var tmpureg = props.ureg
} else  {
  tmpureg = state3
};

if (state4 === "--") {
  var tmpuclass = props.uclass
} else  {
  tmpuclass = state4
};

if (state4a === "--") {
  var tmputype = props.utype
} else  {
  tmputype = state4a
};

if (ureg === "--") {
  tmpureg = props.ureg
} else if (ureg !== "--") {
  tmpureg = ureg;
};

if (uclass === "--") {
  tmpuclass = props.uclass
} else if (uclass !== "--") {
  tmpuclass = uclass;
};

if (utype === "--") {
  tmputype = props.utype
} else if (utype !== "--") {
  tmputype = utype;
};

if (appConfig.useSIP === 'true'){
  if (uip === "--") {
    var tmpuip = props.uip
  } else if (uip !== "--") {
    tmpuip = uip;
  };
} else {
  tmpuip = 'no';
};




if (appConfig.ignoreStanding === "true") {
  tmpustanding = "enabled";
};

if (appConfig.ignoreClass === "true") {
   tmpuclass = "set";
  };

  if (appConfig.burnOn === "true") {
    var tmph4 = true;
    var tmph5 = false;
  } else {
    tmph4 = false;
    tmph5 = true;

   };

if (uopt2 === '1' ) {
  var tmph1 = false;
  var tmph2 = true;
  var tmph3 =  true;
  
} else {
  tmph1= true;
  tmph2 = false;
  tmph3 = false;
  
};




return (

    




<div style={{ textAlign: "center",  maxWidth: "300px" , minHeight: appConfig.minHeightC }}>
<Flex
  direction="column"
  //marginLeft='10px'
  //marginRight='10px'
 // wrap='wrap'
 // gap='10px'
 // marginTop='10px'
 // marginBottom='10px'
 textAlign="center"
 alignItems="center"
 gap = {appConfig.bGap}
>

		{tmputos === "1" ?     (<>



     
     

      {tmpustanding ==="enabled" ? ( 
     
     uopt === '0' ? (
     <>  

 <span hidden = {tmph1}> 
 
        
    <PaypalTime uname={props.uname} funct={setUopt2} />
    </span>
    <span hidden = {tmph2}> 
   
      <button className = 'AT-button' style={{ border: "none", display: "inline", fontSize: "14px", height: "45px", width: "150px" }} onClick={goOpt3}>ADD TIME</button>
      
      
      </span>
</>

     ) : (  uopt3 === '0' ? (
     
     
    <>

<span hidden = {tmph4}> 
     <button className = 'RT-button' style={{ border: "none", display: "inline-block", fontSize: "14px", height: "45px", width: "150px" }} onClick={goReset}>RESET PC</button>
     </span>
     <span hidden = {tmph5}> 
     <button style={{ border: "none",color: "black", backgroundColor: "black", fontWeight: "", display: "inline-block", fontSize: "14px", height: "45px", width: "150px" }} onClick={goBlank}>Blank</button>
      </span>
    </> 
     
     ) :(<>
     
     <span hidden = {tmph4}> 
      <button className = 'RB-button' style={{ border: "none", display: "inline-block", fontSize: "14px", height: "45px", width: "150px" }} onClick={goRollback}>ROLLBACK PC</button>
      </span>

      <span hidden = {tmph5}> 
     <button style={{ border: "none",color: "black", backgroundColor: "black", fontWeight: "", display: "inline-block", fontSize: "14px", height: "45px", width: "150px" }} onClick={goBlank}>Blank</button>
      </span>

     </>)

     )




     ) :( 
     
     tmpustanding ==="disabled" ? (  


      tmpureg ==="not set" ? ( <>
      
      
      
      <Regions uname={props.uname} ureg={props.ureg} funct3b={stateChange3}/>
      
      </> ) : (
      
      
      tmpuclass ==="not set" ? ( <>
      
      
      
      <Systems uname={props.uname} funct4={stateChange4} funct4a={stateChange4a} uclass={tmpuclass} utype={tmputype}/>
      
      
      
      </>) : (


          tmpuip ==="not set" ? ( <><StaticIP uname={props.uname} funct1={stateChange5} /> </> ) : (<><PaypalSubs uname={props.uname} uclass={tmpuclass} utype={tmputype} funct1b={stateChange} /> </>)

    
      )
        
        )



 




      ) :( <></>)

     
     )

  }







        </>) : (
        
          tmputos === "0" ?     (<><br></br>
        
      <button style={{ border: "none", color: "white", backgroundColor: "#6D4C41", fontWeight: "", display: "inline-block", fontSize: "14px", height: "45px", width: "150px" }} onClick={goTOS}>VIEW TERMS</button>
            <br></br><br></br><button style={{ border: "none", color: "white", backgroundColor: "green", fontWeight: "", display: "inline-block", fontSize: "14px", height: "45px", width: "150px" }} onClick={goAcceptTOS}>ACCEPT TERMS</button>
            <br></br>

         </>) : (<></>)



        )    
        }




<span hidden = {tmph3}> 

      {tmputos === "1" ?     (
      
      

      tmpustanding ==="enabled" && tmpureg !== "not set"  ? ( 
        
          uopt ==="0" ? ( 
        <>
        <Flex
         direction="column"
         textAlign="center"
         alignItems="center"
         gap = {appConfig.bGap}
        >
          <button className = 'Misc-button' style={{ border: "none", display: "inline-block", fontSize: "14px", height: "45px", width: "150px" }} onClick={goHelp}>HELP</button>
        
        <button className = 'Misc-button' style={{ border: "none", display: "inline-block", fontSize: "14px", height: "45px", width: "150px" }}  onClick={goOpt1}>OPTIONS</button></Flex>
        </>
          ) :( <>
          
          {uopt3 ==="0" ? (<>
          
            <Flex
         direction="column"
         textAlign="center"
         alignItems="center"
         gap = {appConfig.bGap}
        >
            <button className = 'Misc-button' style={{ border: "none", display: "inline-block", fontSize: "14px", height: "45px", width: "150px" }} onClick={goAlert}>SMS ALERTS</button>

            <><button className = 'Misc-button' style={{ border: "none", display: "inline-block", fontSize: "14px", height: "45px", width: "150px" }}  onClick={goOpt2}>EXIT OPTIONS</button></> 

</Flex>
          </>) : (<>
          
            <Flex
         direction="column"
         textAlign="center"
         alignItems="center"
         gap = {appConfig.bGap}
        >
           <button className = 'Misc-button' style={{ border: "none", display: "inline-block", fontSize: "14px", height: "45px", width: "150px" }}  onClick={goSDT}> SET SD TIME</button>
          
              <button className = 'Misc-button' style={{ border: "none", display: "inline-block", fontSize: "14px", height: "45px", width: "150px" }} onClick={goOpt6}>EXIT OPTIONS</button>

          </Flex>
          </>)} 
          
          
          </>)



        ) : ( <></>)





      
      ):(<></>)}
      
      </span>


      <span hidden = {tmph1}> 
           
        <button className = 'Misc-button' style={{ border: "none", display: "inline-block", fontSize: "14px", height: "45px", width: "150px" }} onClick={goOpt4}>CANCEL PAYPAL</button>
        
      </span>

      </Flex>


</div>







)

}

export default Body;