import React, { useState, useEffect, useRef} from 'react';
import appConfig from './AppConfig';
import logo from './785.svg';
//import PaypalTime from "./PaypalTime";
import {
  Flex,
//  Heading,
//  Image,
//  View,
} from "@aws-amplify/ui-react";

//v1.7b

function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    let id = setInterval(() => {
      savedCallback.current();
    }, delay);
    return () => clearInterval(id);
  }, [delay]);
}



  
//function numberWithCommas(x) {
//  x = x.toString();
//  var pattern = /(-?\d+)(\d{3})/;
//  while (pattern.test(x))
//      x = x.replace(pattern, "$1,$2");
//  return x;
//}


function dispTime(a){
  var h = Math.trunc(a/60);
  var m = a % 60;
  if (h === 0) {
    var hours = '';
  } else if (h === 1) {
     hours = '1hr ';
  } else {
     hours = h + 'hrs ';
  };
  var minutes = m + "min";
  return hours + minutes ;
}











function StatusTime(props) { 



  const [counter, setCounter] = useState(0);
  
const [uname, setUname] = useState("");

const [loader, setLoader] = useState("");

  var tmpcnt = 0;
if (uname === "") {
  tmpcnt = 1;
} else { 
  tmpcnt = 10000;
};
  
  
 
 var raw = JSON.stringify({"uname": props.value,"act": "status_"+ appConfig.apiname});
 var requestOptions = {
     method: 'POST',
     headers: {
             'Content-Type': 'application/json',
           'X-Api-Key': 'tR2J3lBfVD2vYc9TdvtRW1mhT5yn0rpP7fmjN1JV'
             },
     body: raw,
     redirect: 'follow',
  };


  const [fact2, setFact2] = useState("");
  const fetchFact2 = () => {
    fetch("https://pmcbp4q5te.execute-api.us-east-2.amazonaws.com/dev/", requestOptions)
  .then((response) => response.text())
  .then((responce) => setFact2(responce))
 };


  useInterval(() => {
   //setUname(props.value);

   if (props.value === "--") {
    //skip unmae & fetch set
  } else {

    fetchFact2(); 
    if (uname === "") {
      setUname(props.value)
} ;
  }

   

   // if (loader === "2") {
   //   setLoader("3");
   // } else {
   //   setLoader("1");
   // }

    if (counter === 10) {
      setCounter(0);
    } else {
      setCounter(counter + 1);
    }
    
  }, tmpcnt);







  function startButton(uname) {
   
    var raw = JSON.stringify({"uname": uname,"act": "start_"+ appConfig.apiname});
    var requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-Api-Key': 'tR2J3lBfVD2vYc9TdvtRW1mhT5yn0rpP7fmjN1JV'
      },
      body: raw,
      redirect: 'follow'
    };
      fetch("https://pmcbp4q5te.execute-api.us-east-2.amazonaws.com/dev/", requestOptions);
      
  }
  
  
  function stopButton(uname) {
    
    var raw = JSON.stringify({"uname": uname,"act": "stop_"+ appConfig.apiname});
    var requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-Api-Key': 'tR2J3lBfVD2vYc9TdvtRW1mhT5yn0rpP7fmjN1JV'
      },
      body: raw,
      redirect: 'follow'
    };
    fetch("https://pmcbp4q5te.execute-api.us-east-2.amazonaws.com/dev/", requestOptions);
    
  }
  





  
function goButton() {
 
  //////////////////////////////////////
 var tmpstr = fact2.substring( fact2.indexOf("|") + 1,fact2.lastIndexOf("~") );

 var tmpstat = tmpstr.split("!")[0];
 var tmpbank = tmpstr.split("!")[1];
 var tmpustanding = tmpstr.split("!")[5];

  if( tmpustanding === "enabled" ) {

    if (tmpstat === 'Stopped') {
      if (parseInt(tmpbank) < 10 ) {
        alert("Less than 10 min remain, please add more time to continue.")
      } else {
  
     setLoader("starting");
        startButton(props.value)
      };
    } else if (tmpstat === 'Running' ) {
  
     setLoader("stopping");
      stopButton(props.value)
    }

  } else {
      ///
  }

  
 

};






function getDat2(res) {
  var tmpstr = res.substring( res.indexOf("|") + 1,res.lastIndexOf("~") );
  var tmpupass = tmpstr.split("!")[0];
  var tmpuip = tmpstr.split("!")[1];
  //alert(tmpstr)
  //alert("IP: " + tmpuip + ",  User: Administrator,  Pass: " + tmpupass  )

  

var input = window.confirm( "RDP INFO\nIP: " + tmpuip + ",  User: Administrator,  Default Pass: " + tmpupass  + '\n\nDownlaod RDP Connection File?');


if (input) { 
  //true - accepted
  downloadTxtFile()
} else {
  //false - no accepted
}


}


function goBlank() {
  //Open help/support page in new window
  // window.open("https://gsalpha.com/tos");
}

//function goNoPay(){
//  alert("Can not add more time when over "+appConfig.apiname+" min")
//}


function downloadTxtFile() {
 
const currentDate = new Date();
const day = currentDate.getDate();
const month = currentDate.getMonth();
const year = currentDate.getFullYear();
// show in specific format
let datecode = (month+1) + '-' + day + '-' + year ;

  var raw = JSON.stringify({"uname": props.value,"act": "rdpstatus_"+ appConfig.apiname});
  var requestOptions = {
      method: 'POST',
      headers: {
              'Content-Type': 'application/json',
            'X-Api-Key': 'tR2J3lBfVD2vYc9TdvtRW1mhT5yn0rpP7fmjN1JV'
              },
      body: raw,
      redirect: 'follow',
   };
   fetch("https://pmcbp4q5te.execute-api.us-east-2.amazonaws.com/dev/", requestOptions)
   .then(res => res.text()).then((res) => {  

  var tmpstr = res.substring( res.indexOf("|") + 1,res.lastIndexOf("~") );
  var tmpuip = tmpstr.split("!")[1];
  const element = document.createElement("a");
  const txt = "auto connect:i:1\nfull address:s:" + tmpuip +"\nusername:s:Administrator";
  const file = new Blob([txt], {
    type: "text/plain"
  });
  element.href = URL.createObjectURL(file);
  element.download = appConfig.apiname + "_" + datecode + ".rdp";
  document.body.appendChild(element);
  element.click();
});

};



function goRDP() {

  var tmpstr = fact2.substring( fact2.indexOf("|") + 1,fact2.lastIndexOf("~") );

  var tmpstat = tmpstr.split("!")[0];
  

  if (tmpstat === "Running") {





    var raw = JSON.stringify({"uname": props.value,"act": "rdpstatus_"+ appConfig.apiname});
    var requestOptions = {
        method: 'POST',
        headers: {
                'Content-Type': 'application/json',
              'X-Api-Key': 'tR2J3lBfVD2vYc9TdvtRW1mhT5yn0rpP7fmjN1JV'
                },
        body: raw,
        redirect: 'follow',
     };
     fetch("https://pmcbp4q5te.execute-api.us-east-2.amazonaws.com/dev/", requestOptions)
     .then(res => res.text())
    .then(res => getDat2(res) )
    .catch(err => console(err) )
    

    //alert("IP: " + uip + ",  User: Administrator,  Pass: " + upass  )
  }
}


  



  var tmpstr = fact2.substring( fact2.indexOf("|") + 1,fact2.lastIndexOf("~") );
  var tmpstat = tmpstr.split("!")[0];

  if (tmpstat.includes("AWS")) {
    alert(tmpstat)
  }


  var tmpdur = tmpstr.split("!")[2];
   var tmpbank = tmpstr.split("!")[1];
   var tmpdet = tmpstr.split("!")[3];

   var tmpstate = tmpstr.split("!")[4];
   var tmpstate2 = tmpstate;
   //var tmpustanding = tmpstr.split("!")[5];
   var tmpustanding = props.ustanding;
   var tmputos = props.utos;
   //var tmpureg = tmpstr.split("!")[10];
   var tmpureg = props.ureg
   //var tmputype = tmpstr.split("!")[8];
   var tmputype = props.utype
   //var tmpuclass = tmpstr.split("!")[12];
   var tmpuclass = props.uclass
   var tmpucpu = tmpstr.split("!")[13];
   //var tmpuip = tmpstr.split("!")[17];
   var tmpuip = props.uip;
  var tmpDstatus = "Loading...";
  var tmpUshow = "";
 //  var tmpUbutton = "";
  var tmpDbutton = "";
  var tmpUbcolor = "black";
  var tmpUbcolor2 = "black";
  var tmpUstatus = "Loading...";
  //var tmpUdet = "";
  var tmpUspin = "";
 // var tmpUmessage = "";
   var tmpUdur = "";
  var tmpUbank = "";
    var tmpusdt = tmpstr.split("!")[18];
var tmpuSD = "";
var tmpBClass = 'BK-button';
var tmpDClass = 'BK-button';

   if (tmpdet === "Backing Up" ) {
    tmpDstatus = "Backing Up"
  } else if (tmpdet === 'Verifying') {
    tmpDstatus = "Backing Up"
  } else if (tmpdet === 'Checking Status') {
    tmpDstatus ="Checking Status"
  } else {

    tmpDstatus = tmpstat
  
  };

    if (appConfig.burnOn === "true") {

      if (tmpstate === "starting" && tmpstat !== "Running") {tmpDstatus="Creating PC"} else if (tmpstate ==="stopping" && tmpstat !== "Stopped") {tmpDstatus="Destroying PC"} else {tmpDstatus = tmpstat}
   
    }

   





  //////////////////////////
  if (appConfig.ignoreStanding === "true") {
    tmpustanding = "enabled";
  }
  
  if (appConfig.ignoreClass === "true") {
     tmpuclass = "set";
    }
///////////////////////////////  


    if (appConfig.showSys === "true") {

      if (tmpuclass === 'a4') { 
        if (tmputype === '1') { 
          var tmpSys = ' GS1'   
        } else {
           tmpSys = ' GS2'
        }
      
      } else if (tmpuclass === 'g4ad') {
        tmpSys='S4';

      } else {
      
        if (tmpuclass === 't3m') {
            
              tmpSys = " S1";
        } else if ( tmpuclass === 't3l' ) {

          if (tmpucpu === '1') {tmpSys = ' S2' } else {tmpSys = ' S3'}


         } else {


          tmpSys = " " + tmpuclass.toUpperCase() +'-'  + tmpucpu

          }
      };
      



      if (tmpureg === 'us-west-2') {tmpSys = tmpSys + '-W'} else if (tmpureg === 'us-east-1') {tmpSys = tmpSys + '-E'} else {tmpSys = tmpSys + 'u'}


    } else {
        tmpSys = ""      
    };


    





    if (loader === "starting" ) {
    
      if (tmpstat === "Starting") {
        //clear loader
        setLoader("")

   //loader override
     // tmpDbutton='LD1-PROCESSING';
      //tmpDbutton='SENDING COMMAND';
      tmpBClass = 'PR-button';
      tmpUspin=true;
      tmpDClass = 'App-processing';
      tmpUshow='1';  
      tmpUbank=dispTime(tmpbank);
      tmpuSD =   "";
      tmpstate = "starting";
      //alert(tmpstat);
      tmpstat = "DATA1B";
      
      tmpUshow='1';  
      tmpUdur="";


      } else {
      
      //loader override
     // tmpDbutton='LD1-PROCESSING';
      //tmpDbutton='SENDING COMMAND';
      tmpBClass = 'PR-button';
      tmpUspin=true;
      tmpDClass = 'App-processing';
      tmpUshow='1';  
      tmpUbank=dispTime(tmpbank);
      tmpuSD =   "";
      tmpstate = "starting";
      //alert(tmpstat);
      tmpstat = "DATA1A";
      
      tmpUshow='1';  
      tmpUdur="";

      };
        
  
    } else if (loader === "stopping") {
  
      if (tmpstat === "Stopping") {
        //clear loader
        setLoader("")


            //loader override
    //  tmpDbutton='LD2-PROCESSING';
     // tmpDbutton='SENDING COMMAND';
      tmpBClass = 'PR-button';
      tmpUspin=true;
      tmpDClass = 'App-processing';
      tmpUshow='1';  
      tmpUbank=dispTime(tmpbank);
      tmpuSD =   "";
      tmpstate = "stopping";
      //alert(tmpstat);
      tmpstat = "DATA2B";
  
      tmpUshow='1';  
      tmpUdur="";

      } else {

      //loader override
    //  tmpDbutton='LD2-PROCESSING';
     // tmpDbutton='SENDING COMMAND';
      tmpBClass = 'PR-button';
      tmpUspin=true;
      tmpDClass = 'App-processing';
      tmpUshow='1';  
      tmpUbank=dispTime(tmpbank);
      tmpuSD =   "";
      tmpstate = "stopping";
      //alert(tmpstat);
      tmpstat = "DATA2A";
  
      tmpUshow='1';  
      tmpUdur="";
      };
  
     };




/////////////////////////////////////////Main 




  if (tmpstate === "starting" ) {
    //Starting State 
    tmpUshow = '1'
    if (tmpstat === "Stopped" ) {
      // skip
    } else if (tmpstat === 'Running') {
 //     tmpUbutton='STOP';
      tmpDbutton='STOP' ;
      //tmpUbcolor='#900003';
      //tmpUbcolor2='yellow';
      tmpDClass = 'App-running';
      tmpBClass = 'SP-button';
      if (appConfig.burnOn === "true") {tmpDbutton='DESTROY PC'};
      tmpUstatus=tmpstat; 
      //tmpUdet="";
      //tmpUbank=tmpbank + " min";
      //var tmpSD = dispTime(Number(tmpusdt) - Number(tmpdur));
      var tmpSD = dispTime(tmpusdt);
      tmpUbank=dispTime(tmpbank) + " / " ;
      tmpuSD =   tmpSD;
      //tmpUmessage="";
      tmpUdur=" (" + dispTime(tmpdur) + ")";
      
      tmpUspin =false;
     // tmpUmessage=tmpitype
    } else if (tmpstat === "") {
      // skip
    } else {
  //    tmpUbutton='PROCESSING';
      tmpDbutton='PROCESSING';
     // tmpUbcolor='#4d4d4d';
      tmpBClass = 'PR-button';
      //tmpUbcolor2='yellow';
      tmpDClass = 'App-processing';
      if (appConfig.burnOn === "true") {tmpDstatus="Creating PC"};
      tmpUstatus=tmpstat;
      //tmpUdet=" - " + tmpdet;
     // tmpUbank=tmpbank + " min";
     tmpUbank=dispTime(tmpbank);
    // tmpuSD="SD: " + dispTime(tmpusdt);
      tmpUspin=true;
      //tmpUmessage="";    
      tmpUdur="";
     // tmpUmessage=tmpitype
    };

    
  } else if (tmpstate === "stopping") {
    //Stopping State

    if (tmpstat === "Stopped" ) {
      if (tmputos === "1") {
        if (tmpustanding ==="enabled" && tmpureg !== "not set" ) {
        //enabled account 
   //     tmpUbutton='START';
        tmpDbutton='START' + tmpSys;
       // tmpUbcolor='green';
        //tmpUbcolor2='#4CAF50';
        tmpDClass = 'App-stopped';
        tmpBClass = 'ST-button';
        if (appConfig.burnOn === "true") {tmpDstatus='Ready';tmpDbutton='CREATE PC'} else {tmpDstatus= tmpstat};
        tmpUstatus=tmpstat; 
        //tmpUdet="";
        //tmpUbank=tmpbank + " min";
        tmpUbank=dispTime(tmpbank) ;
    //    tmpuSD="SD: " + dispTime(tmpusdt);
        tmpUspin=false;
        //tmpUmessage="";
        
        tmpUdur="";
        tmpUshow='1';
        //tmpUmessage=tmpitype
      } else if (tmpureg ==="not set") {
        //Region not set
        tmpDbutton='DISABLED';
        //tmpUbcolor='#4d4d4d';
        tmpBClass = 'PR-button';
        //tmpUbcolor2='yellow';
        tmpDClass = 'App-processing';
        tmpUstatus=tmpstat; 
        tmpDstatus="Please select a Region"
        //tmpUbank=tmpbank + " min";
       
        tmpUspin=false;
        tmpUshow='0';
        tmpUdur=""
       } else {

          if (tmpuclass ==="not set") { 
         //Class not set
        tmpDbutton='DISABLED';
        //tmpUbcolor='#4d4d4d';
        //tmpUbcolor2='yellow';
        tmpDClass = 'App-processing';
        tmpBClass = 'PR-button';
        tmpUstatus=tmpstat; 
        tmpDstatus="Please select a System"
        //tmpUbank=tmpbank + " min";
         tmpUspin=false;
        tmpUshow='0';
        tmpUdur=""


          } else {



            if (tmpuip ==="not set") { 
              //Class not set
             tmpDbutton='DISABLED';
             //tmpUbcolor='#4d4d4d';
             tmpBClass = 'PR-button';
             //tmpUbcolor2='yellow';
             tmpDClass = 'App-processing';
             tmpUstatus=tmpstat; 
             tmpDstatus="Please select an IP Address Option"
          //   tmpUbank=tmpbank + " min";
              tmpUspin=false;
             tmpUshow='0';
             tmpUdur=""
     
     
               } else {

          //disabled account
    //    tmpUbutton='DISABLED';
          tmpDbutton='DISABLED';
          //tmpUbcolor='#4d4d4d';
          tmpBClass = 'PR-button';
          //tmpUbcolor2='#4CAF50';
          tmpUstatus=tmpstat; 
           //tmpUdet="";
       //    tmpUbank=tmpbank + " min";
            tmpUspin=false;
            //tmpUmessage="Please pay storage fee to activate account";
           //tmpUmessage = "--";
           tmpUbcolor2='yellow';
           tmpUshow='0';
            //tmpDstatus="-";
            tmpDstatus="Please subscribe via PayPal to activate account";
           tmpUdur=""
          }
        }

        };
       } else if (tmputos ==="0") {
        //TOS not accepted
   //     tmpUbutton='PROCESSING';
        tmpDbutton='DISABLED';
        //tmpUbcolor='#4d4d4d';
        tmpBClass = 'PR-button';
        //tmpUbcolor2='#4CAF50';
        //tmpUbcolor2='yellow';
        tmpDClass = 'App-processing';
        tmpUstatus=tmpstat; 
        tmpDstatus="Please rewiew and accept the Terms Of Service"
       // tmpUdet="";
   //     tmpUbank=tmpbank + " min";
        tmpUspin=false;
        //tmpUmessage="Please read & accept the 'Terms Of Service'";
        //tmpUmessage="--";
        tmpUshow='0';
        //tmpDstatus="-";
        tmpUdur=""

     



      };

     } else if (tmpstat === 'Running') {
     // skip
      tmpUshow='1'
     } else if (tmpstat === "") {
      // skip
      tmpUshow='0'
      tmpDbutton='PROCESSING';
      //tmpUbcolor='#4d4d4d';
      tmpBClass = 'PR-button';
      //tmpUbcolor2='yellow';
      tmpDClass = 'App-processing';
      tmpUstatus="Loading";
      tmpDstatus="Loading Account";
      //tmpUdet=" - " + tmpdet;
    //  tmpUbank="";
      tmpUspin=true;
     // tmpUmessage="";
      tmpUshow='1';  
      tmpUdur="";



      
     } else if (tmpstat === "Bad Account Name") { 
      tmpUshow='0'
      tmpDbutton='PROCESSING';
      //tmpUbcolor='#4d4d4d';
      tmpBClass = 'PR-button';
      //tmpUbcolor2='yellow';
      tmpDClass = 'App-processing';
      tmpUstatus="Loading";
      tmpDstatus="Loading Account " + props.value + "/" + uname;
      //tmpUdet=" - " + tmpdet;
   //   tmpUbank="";
      tmpUspin=true;
     // tmpUmessage="";
      tmpUshow='1';  
      tmpUdur="";

     } else if (tmpstat === "No Account Found") {




            //    tmpUbutton='DISABLED';
      tmpDbutton='DISABLED';
      //tmpUbcolor='#4d4d4d';
      tmpBClass = 'PR-button';
      //tmpUbcolor2='yellow';
      tmpDClass = 'App-processing';
      tmpUstatus=tmpstat; 
      //tmpUdet="";
    //  tmpUbank=tmpbank + " min";
      tmpUspin=false;
      tmpDstatus="No Account Found. Please request access"
      //tmpUmessage="Please request access at www.gsalpha.com";
      //tmpUmessage = "--";
      tmpUshow='1';
      tmpUdur="";
      tmpUshow='0'



        




     } else {
   //   tmpUbutton='PROCESSING';
      tmpDbutton='PROCESSING';
      //tmpUbcolor='#4d4d4d';
      tmpBClass = 'PR-button';
      //tmpUbcolor2='yellow';
      tmpDClass = 'App-processing';
      if (appConfig.burnOn === "true") {tmpDstatus="Destroying PC"};
      tmpUstatus=tmpstat;
      //tmpUdet=" - " + tmpdet;
     // tmpUbank=tmpbank + " min";
     tmpUbank=dispTime(tmpbank);
    // tmpuSD="SD: " + dispTime(tmpusdt);
      tmpUspin=true;
     // tmpUmessage="";
      tmpUshow='1';  
      tmpUdur="";
      //tmpUmessage=tmpitype

     };
  
   
   

  
  };


  


  


   




    return (


      
        <>

          
       <div style={{ color: 'white' , fontWeight: "normal", textAlign: "center", maxWidth: '400px' , margin: '10px', minHeight:'72px', fontSize: '14px'}}> 
       <span className = {tmpDClass} style={{fontWeight: "normal",fontSize: '24px'}}>  {tmpDstatus}{tmpUdur}<br></br></span>
       

        {tmpustanding ==="enabled" && tmpureg !== "not set"  ? (

       tmpUshow === '0' ? (<> -- </>):(<>
       <span className = 'App-bank' style={{fontSize: '24px'}}>{tmpUbank}</span><span className = 'App-SDT' style={{fontSize: '24px'}}>{tmpuSD}</span>
       
        </>
       )
  ) :(< ></>)}

</div> 
  
         



<Flex
         direction="column"
         textAlign="center"
         alignItems="center"
        gap =  {appConfig.bGap}
        >

      {tmputos === "1" ? (
      
      tmpustanding ==="enabled" && tmpureg !== "not set"  ? (<>
        
          
          <button className = {tmpBClass} style={{ border: "none",  display: "inline-block", marginTop: "10px", fontSize: "14px", height: "45px", width: "150px" }} onClick={goButton}>{tmpDbutton}</button>


        </>):(<></>)
      
      
      ):(<></>) }
         




        
        
        
       
      { tmpUspin === true ? ( <><Flex 
      
      direction="column"
      //marginLeft='10px'
      //marginRight='10px'
     // wrap='wrap'
     // gap='10px'
 //     marginTop='15px'
  //    marginBottom='calc(15px + 6px)'
     textAlign="center"
     alignItems="center"
     justifyContent= "center"
     gap = '0px'
     minHeight={appConfig.minHeightD }
      ><img src={logo} className="App-logo" alt="logo" /></Flex></> ) : ( 
        
  
        tmputos === "1" ? (<>
        
          {tmpUstatus === "Running" ? ( <>  
  
        <button className = 'RDP-button' style={{ border: "none", display: "inline-block", fontSize: "14px", height: "45px", width: "150px" }} onClick={goRDP}>RDP CONNECT</button> <br></br>
        </>
          ) : (        
          
          
            tmpustanding ==="enabled" && tmpureg !== "not set"  ? ( <>
             <button style={{ border: "none", color: "black", backgroundColor: "black", fontWeight: "", display: "inline-block", fontSize: "14px", height: "45px", width: "150px" }} onClick={goBlank}>BLANK</button> <br></br>
                
          </>) : ( <></>)
                
                
                 )}
  
  
        </>
  
        ) : (<></>) 
  
  
        
         ) }

</Flex>

 <span hidden>{tmpstat}-{tmpstate}-2({tmpstate2}) L{loader}</span>
         </>
      );
    



    
}
    export default StatusTime;

