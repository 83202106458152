//v1.7
const appConfig = {
    "appname": "WorkCPU",  
    "webtitle": "WorkCPU Portal", 
    "apiname": "vbc", 
    "websitename": "workcpu.com", 
    "showSys": "false",
    "maxcred":2000,
    "minHeight":'530px',  //non-gsa
    "minHeightB":'375px', //non-gsa - sign out inistal offset
    "minHeightC":'0px', //non-gsa
    "minHeightD":'75px', //non-gsa
    "bGap":"6px",
   //"minHeight":'562px', //GSA
   //"minHeightB":'427px', //GSA
   //   "minHeightD":'72px', //GSA
    //VGS
    "useSIP":"false",
    //BurnerPC
    "ignoreStanding": "false",
    "ignoreClass": "false",
    "burnOn": "false",
};

export default appConfig;
