import React, { useState } from 'react';
import appConfig from './AppConfig';

//1.3


function Regions(props) { 


 
  const [state3, setState3] = useState("--");
    


function stateChange3(f) {
  setState3(f);
  props.funct3b(f);
  props.ureg = f;
 
};




  function goUSW() {
    //Open help/support page in new window
    // window.open("https://gsalpha.com/tos");
    
    const input = "us-west-2";
      regButton(input)  
  }

  
  function goUSE() {
    //Open help/support page in new window
    // window.open("https://gsalpha.com/tos");
    
    const input = "us-east-1";
      regButton(input)  
  }




  function regButton(data) {
    var raw = JSON.stringify({"uname": props.uname,"act": "reg_" + appConfig.apiname,"dat": data});
    var requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-Api-Key': 'tR2J3lBfVD2vYc9TdvtRW1mhT5yn0rpP7fmjN1JV'
      },
      body: raw,
      redirect: 'follow'
    };
    fetch("https://pmcbp4q5te.execute-api.us-east-2.amazonaws.com/dev/", requestOptions);
  
    alert("Region Updated");
    stateChange3(data)
  }




       return (<>
       
     
       <button style={{ border: "none", color: "white", backgroundColor: "#6D4C41", fontWeight: "", display: "inline-block", fontSize: "14px", height: "45px", width: "150px" }} onClick={goUSW}>US WEST</button>
    <br></br>  
    <br></br>
       <button style={{ border: "none", color: "white", backgroundColor: "#6D4C41", fontWeight: "", display: "inline-block", fontSize: "14px", height: "45px", width: "150px" }} onClick={goUSE}>US EAST</button>
      

       </>
);

}

export default Regions;









