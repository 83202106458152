import React, { useState } from 'react';
import appConfig from './AppConfig';




function Systems(props) { 


 
  const [state4, setState4] = useState("--");
  



function stateChange4(f) {
  setState4(f);
  props.funct4b(f);
  
};


function goSys1() {
  //Open help/support page in new window
  // window.open("https://gsalpha.com/tos");
  
  const input = "t3m1";
    classButton(input)  
}

function goSys2() {
  //Open help/support page in new window
  // window.open("https://gsalpha.com/tos");
  
  const input = "t3m2";
    classButton(input)  
}

function classButton(data) {
  var raw = JSON.stringify({"uname": props.uname,"act": "class_"+ appConfig.apiname,"dat": data});
  var requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Api-Key': 'tR2J3lBfVD2vYc9TdvtRW1mhT5yn0rpP7fmjN1JV'
    },
    body: raw,
    redirect: 'follow'
  };
  fetch("https://pmcbp4q5te.execute-api.us-east-2.amazonaws.com/dev/", requestOptions);
  
  alert("System Updated");
  stateChange4(data)
}






       return (<>
       
     
       Desktop1 $0.20/hr
      <button style={{ border: "none", color: "white", backgroundColor: "#6D4C41", fontWeight: "", display: "inline-block", fontSize: "14px", height: "45px", width: "150px" }} onClick={goSys1}>1x 2.50Ghz vCPU PC 4GB RAM</button>
      
      
      Desktop2 $0.30/hr
      <button style={{ border: "none", color: "white", backgroundColor: "#6D4C41", fontWeight: "", display: "inline-block", fontSize: "14px", height: "45px", width: "150px" }} onClick={goSys2}>2x 2.50Ghz vCPU PC 4GB RAM</button>
      

       </>
);

}

export default Systems;









