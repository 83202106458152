import React, { useState, useEffect } from 'react';
import './App.css';
//import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
//import logo from './785.svg';

import StatusTime from "./StatusTime";
import Body from "./Body";
import appConfig from './AppConfig';
import { Auth } from 'aws-amplify'
import '@aws-amplify/ui-react/styles.css';
import { Amplify } from 'aws-amplify';
import { withAuthenticator } from '@aws-amplify/ui-react';
import background from "./main_background.png";
import {
  Flex,
//  Heading,
//  Image,
//  View,
} from "@aws-amplify/ui-react";

import awsExports from './aws-exports';
Amplify.configure(awsExports);

//1.7
  


function App({signOut}) {
//  const [fact2, setFact2] = useState("");

  const [uname, setUname] = useState("--");
  const [ustat, setUstat] = useState("--");



  const [utos, setUtos] = useState("--");
  const [usci, setUsci] = useState("--");
  const [ureg, setUreg] = useState("--");
  const [uclass, setUclass] = useState("--");
  const [utype, setUtype] = useState("--");
  const [uip, setUip] = useState("--");
  const [ustanding, setUstanding] = useState("--");
  

  
  function getDat(res) {
    var tmpstr = res.substring( res.indexOf("|") + 1,res.lastIndexOf("~") );
    var tmpstat = tmpstr.split("!")[0];
    var tmpustanding = tmpstr.split("!")[5];
    var tmputos = tmpstr.split("!")[7];
     var tmputype = tmpstr.split("!")[8];
    var tmpureg = tmpstr.split("!")[10];
    var tmpuclass = tmpstr.split("!")[12];
    var tmpusci = tmpstr.split("!")[14];

    var tmpuip = tmpstr.split("!")[17];
    
   
    setUstat(tmpstat);
    setUstanding(tmpustanding);
    setUtos(tmputos);
    setUsci(tmpusci);
    setUreg(tmpureg);
    setUclass(tmpuclass);
    setUtype(tmputype);
    setUip(tmpuip);
  }




  useEffect(() => {

    async function statusButton2() {
   
      const user = await Auth.currentAuthenticatedUser();
     var tmpuname = user.attributes.email;
      //alert(tmpuname);
    
       var raw = JSON.stringify({"uname": tmpuname,"act": "status_"+ appConfig.apiname});
       var requestOptions = {
           method: 'POST',
           headers: {
                   'Content-Type': 'application/json',
                 'X-Api-Key': 'tR2J3lBfVD2vYc9TdvtRW1mhT5yn0rpP7fmjN1JV'
                   },
           body: raw,
           redirect: 'follow',
        };
    
    
     fetch("https://pmcbp4q5te.execute-api.us-east-2.amazonaws.com/dev/", requestOptions)
     .then(res => res.text())
    .then(res => getDat(res) )
    .catch(err => alert(err) )
    
    };




    checkUser();statusButton2()
  }, []);



 
  useEffect(() => {
    //document.title = "VG Servers Portal"
  document.title = appConfig.webtitle
  }, []);


  async function checkUser() {
    const user = await Auth.currentAuthenticatedUser();
    setUname(user.attributes.email);
   }
 
 




var fact2 = "--";

//<div className="App" style={{ backgroundImage: `url(${backgroundA})`, backgroundRepeat:"no-repeat",backgroundSize:"contain",backgroundPosition:"center"  }} >

  return (

    <div className="App" style={{ alignItems:'center',  justifyContent: 'center',  textAlign: 'center',  display: 'flex', height:'100vh', backgroundImage: `url(${background})`, backgroundRepeat:"no-repeat",backgroundSize:"cover",backgroundPosition:"center", minHeight: appConfig.minHeight   }}>
      
      
      
      
      <div className = "App" style={{  display: "inline",  fontWeight: "normal",color: "white",  border: "4px" , borderStyle: "solid" , borderColor: "black" ,  maxWidth: '400px', minWidth: '400px'   ,textAlign: "center" , minHeight: appConfig.minHeight }} >
      
    
      <span className = 'App-title' style={{fontSize: '30px'}}>{appConfig.appname}</span><br></br>
      <span className = 'App-user' style={{ fontSize: '23px'  }}>{uname}</span><br></br>
      
     
    
     
      <Flex
         direction="column"
         textAlign="center"
         alignItems="center"
        gap='0'
        minHeight={appConfig.minHeightB}
        >
      <StatusTime value={uname} utos={utos} ustanding={ustanding} ureg={ureg} uclass={uclass} uip={uip} utype={utype} key={fact2} key2={fact2} />

     
    
            
              <><Body uname={uname} utos={utos} ustanding={ustanding} usci={usci} uip={uip} key={fact2} key2={fact2}  funct={setUstanding} funct2={setUtos} funct3={setUreg} funct4={setUclass} funct4a={setUtype} funct5={setUip} ustatus={ustat} ureg={ureg} uclass={uclass} utype={utype}  /></>

      </Flex>
             

     

              <Flex
         direction="column"
         textAlign="center"
         alignItems="center"
         marginTop={appConfig.bGap}
         //gap = {appConfig.bGap}
        >
            <button className = 'SO-button' style={{ border: "none",display: "inline-block", fontSize: "14px", height: "45px", width: "150px" }} onClick={signOut}>SIGN OUT</button>
           </Flex>
            
      
      

      </div>
      
    </div>
    
  );
}
 
//"#e6ac00" and #ffb300 old help button color

export default withAuthenticator(App);

