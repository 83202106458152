import React, { useState } from 'react';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import appConfig from './AppConfig';






function PaypalSubs(props) { 


  const [state, setState] = useState("--");


    


function stateChange(f) {
setState(f);
props.funct1b(f);
props.ustanding = f;
};


function goPaypal() {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  //myHeaders.append("Content-Type", "text/plain");
  
  var raw = JSON.stringify({"uname": props.uname,"act": "addSub_" + appConfig.apiname});

  var requestOptions = {
   method: 'POST',
   headers: {
    'Content-Type': 'application/json',
    'X-Api-Key': 'tR2J3lBfVD2vYc9TdvtRW1mhT5yn0rpP7fmjN1JV'
  },
   body: raw,
   redirect: 'follow'
 };
   // make API call with parameters and use promises to get response
  fetch("https://pmcbp4q5te.execute-api.us-east-2.amazonaws.com/dev/", requestOptions);

     alert("Test Subscription Payment Successful");stateChange("enabled")
 }







 function addSubButton100(data) {
  var myHeaders = new Headers();
   myHeaders.append("Content-Type", "application/json");
   //myHeaders.append("Content-Type", "text/plain");
   
   var raw = JSON.stringify({"uname": props.uname,"act": "addSub_"+ appConfig.apiname});

   var requestOptions = {
    method: 'POST',
    headers: {
     'Content-Type': 'application/json',
     'X-Api-Key': 'tR2J3lBfVD2vYc9TdvtRW1mhT5yn0rpP7fmjN1JV'
   },
    body: raw,
    redirect: 'follow'
  };
    // make API call with parameters and use promises to get response
   fetch("https://pmcbp4q5te.execute-api.us-east-2.amazonaws.com/dev/", requestOptions);
   alert("Subscription Payment Successful");stateChange("enabled")
 }


 function addSubButton50(data) {
   var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    //myHeaders.append("Content-Type", "text/plain");
    
    var raw = JSON.stringify({"uname": props.uname,"act": "addSub150_"+ appConfig.apiname});

    var requestOptions = {
     method: 'POST',
     headers: {
      'Content-Type': 'application/json',
      'X-Api-Key': 'tR2J3lBfVD2vYc9TdvtRW1mhT5yn0rpP7fmjN1JV'
    },
     body: raw,
     redirect: 'follow'
   };
     // make API call with parameters and use promises to get response
    fetch("https://pmcbp4q5te.execute-api.us-east-2.amazonaws.com/dev/", requestOptions);
    alert("Subscription Payment Successful");stateChange("enabled")
  }









 
const SubscriptionButtonWrapper = () => {


  


  return (<>

 


  50GB Storage $3.00/mo<PayPalButtons
    createSubscription={(data, actions) => {
      return actions.subscription
        .create({
          plan_id: "P-47T21729RX3911207MPIF7RI" 
        });
    }}
    style={{
      label: "subscribe",
      layout: "horizontal",
      tagline: false,
      color: "white",
      height: 45
    }}
    onApprove={(data, actions) => {
      return actions.subscription.capture(  addSubButton50(data)  );
     }}
  />
  
  <br></br>
  100GB Storage $5.75/mo<PayPalButtons
    createSubscription={(data, actions) => {
      return actions.subscription
        .create({
          plan_id: "P-8PJ50060614965415MPIGPVI" ,
        });
    }}
    style={{
      label: "subscribe",
      layout: "horizontal",
      tagline: false,
      color: "white",
      height: 45
    }}
    onApprove={(data, actions) => {
      return actions.subscription.capture(  addSubButton100(data)  );
     }}
  />
  
  
  
  </>);
}









       return (<>
       
     
       <PayPalScriptProvider
			options={{
				"client-id": "AT-bDu0WdCz2cOExWJ6H6jJg9HhVJ8kw5I15q9rLvbFPqfNgUhdnzO_7ZdIMVzMB19Fs0q_cD9oaCaCb", 
				components: "buttons",
				intent: "subscription",
				vault: true,
			}}
		>
			<SubscriptionButtonWrapper /> 


		</PayPalScriptProvider>




    <br></br>Test 100GB Storage<button style={{ border: "none",color: "white", backgroundColor: "#900003", fontWeight: "", display: "inline-block", fontSize: "14px", height: "45px", width: "150px" }} onClick={goPaypal}>SKIP PAYPAL</button>
     


       </>
);

}

export default PaypalSubs;









