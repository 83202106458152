import React, { useState } from 'react';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import appConfig from './AppConfig';
import { Flex } from '@aws-amplify/ui-react';




//1.7

function PaypalTime(props) { 

  
  const [state4, setState4] = useState("--");
 
  

function stateChange4(f) {
  setState4(f);
  props.funct(f);
  
};



    function addTimeButton(data) {









        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        //myHeaders.append("Content-Type", "text/plain");
        
        var raw = JSON.stringify({"uname": props.uname,"act": "addTime_"+ appConfig.apiname});
    
        var requestOptions = {
         method: 'POST',
         headers: {
          'Content-Type': 'application/json',
          'X-Api-Key': 'tR2J3lBfVD2vYc9TdvtRW1mhT5yn0rpP7fmjN1JV'
        },
         body: raw,
         redirect: 'follow'
       };
         // make API call with parameters and use promises to get response
        fetch("https://pmcbp4q5te.execute-api.us-east-2.amazonaws.com/dev/", requestOptions);
        alert("Payment Successful");
        stateChange4("0")
      }
    
    
      const OrderButtonWrapper = () => {
        
        return (
        <>Add $5.00
        <PayPalButtons
          createOrder={(data, actions) => {
            return actions.order.create({
                          purchase_units: [{
                              amount: {
                                  value: '5.00'
                              }
                          }]
                      });
          }}
          style={{
            layout: "horizontal",
            tagline: false,
            height: 45,
            color: "gold"
          }}
          onApprove={(data, actions) => {
            return actions.order.capture(  addTimeButton(data)  );
           }}
        /></>);
      };






       return (<>
<Flex 
 direction="column"
 textAlign="center"
 alignItems="center"
maxWidth = "150px"
minHeight = "96px"
gap = "0px"
>




       <PayPalScriptProvider
    options={{
        "client-id": "AT-bDu0WdCz2cOExWJ6H6jJg9HhVJ8kw5I15q9rLvbFPqfNgUhdnzO_7ZdIMVzMB19Fs0q_cD9oaCaCb", 
        components: "buttons",
        "data-namespace": "paypalOrder"
    }}
>
    <OrderButtonWrapper />
</PayPalScriptProvider>

  
       <button style={{ border: "none",color: "black", marginTop: "0px", marginBottom: "0px" , backgroundColor: "#4d4d4d", color: "white" , display: "inline-block", fontSize: "12px", height: "21px", width: "150px" }} onClick={addTimeButton}>TEST PAYPAL</button>

</Flex>    

</>
);

}

export default PaypalTime;









